body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primary-button {
  font-size: 1.1em;
  background-color: #5D5DED;
  border: 2px solid #000;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 10px;
  font-weight: bold;
  padding: 10px 20px;
  margin-top: 10px;
  height: 45px;
  transition: background-color 0.3s ease;
}

.primary-button:hover {
  background-color: green;
}

.secondary-button {
  border: #5D5DED;
  background-color: #5D5DED;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  color: #FFFFFF;
  cursor: pointer;
  padding: 10px 20px;
  max-width: 150px;
}

/*Style the button like text with underline*/
.tertiary-button {
  border: none;
  color: #5D5DED;
  background-color: transparent;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
}

.sample-section-box {
  text-align: left;
  border: 2px solid black;
  padding: 1em;
  margin: 1em;
  background-color: white;
  border-radius: 5px;
  font-size: 1em;
  line-height: 1.5em;
}

.sample-section-box h3 {
  margin: 0 0 0.5em 0;
  padding: 0;
}

.sample-section-box a {
  color: #5D5DED;
  text-decoration: none;
}

.sample-section-box img {
  width: 100%;
}

.tertiary-info-button {
  border: 2px solid black;
  font-size: 1.1em;
  padding: 8px 18px;
  border-radius: 10px;
  background-color: white;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #d9d9d9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  top: -10%;
  transform: translateX(-50%);
  z-index: 1;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
}

.primary-input {
  width: 40em;
  padding: 16px 8px;
  margin-bottom: 8px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #d1d1d1;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  color: rgb(4, 69, 175);
}

.emphasis-icon {
  width: 1.5em;
}

.detail-checkbox {
  border: 5px solid black;
  padding: 1em;
  background-color: white;
  border-radius: 5px;
  height: 15em;
  width: 27em;
  font-size: 1em;
  line-height: 1.5em;
}


.tag {
  border: 2px solid black;
  border-radius: 5px;
  padding: 3px;
  min-width: 100px;
  font-size: .75em;
}

.detail-checkbox-icons svg {
  cursor: pointer;
}
.detail-checkbox h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.primary-input-container {
  border: 5px solid black;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.onboarding-header {
  margin: 0.25em 0;
}

.onboarding-subtext {
  margin-top: 0.5em
}

.onboarding-popup {
  position: fixed;
  transition: transform 0.3s ease-in-out;
  opacity: 1;
  transform: translateX(-50%);
  background: white;
  border: 1px solid black;
  border-radius: 8px;
  padding: 20px;
  z-index: 1001;
  width: 80%;
  max-width: 400px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.onboarding-popup.right {
  transform: translateX(100%);
}

.onboarding-popup.left {
  transform: translateX(-100%);
}

.onboarding-popup ul {
  text-align: left;
  line-height: 1.5em;
}

.accordion {
  background-color: white;
  border: 5px solid black;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 35em;
  line-height: 1.5em;
}
.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}
.accordion-label {
  font-size: 18px;
  font-weight: bold;
  color: black;
}
.onboarding-list li {
  margin-bottom: 15px;
}
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  max-width: 30em;
  margin: auto
}

.accordion-content.active {
  max-height: 500px; /* Adjust as needed */
  opacity: 1;
}
.accordion-arrow {
  transition: transform 0.3s ease; /* Match timing with slide transition */
  display: inline-block;
}

.accordion-arrow.active {
  transform: rotate(180deg); /* Rotate 180 degrees */
}



.accordion-divider {
  height: 5px;
  width: 100%;
  background-color: black;
}

.primary-input::placeholder {
  color: #a1a1a1;
  font-weight: 300;
}

.primary-input:focus {
  border-bottom-color: #5D5DED;
}

.sidebar-item {
  padding-left: 1em;
  width: 100%;
  cursor: pointer;
  border-radius: 0;
  transition: background-color 0.3s ease;
  color: #333;
  box-sizing: border-box;
}

.sidebar-item:hover {
  background-color: #f0f0f0;
}

.sidebar-item svg {
  width: 1em;
}

.sidebar-settings {
  padding: 12px;
  cursor: pointer;
  border-top: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.sidebar-settings:hover {
  background-color: #f0f0f0;
}

/* highlight active page in the sidebar */
.sidebar-item.active, .sidebar-settings.active {
  background-color: #eaf1f6;
  font-weight: bold;
}

.header-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 0.25px;
  padding: 5px 10px;
}

.inline-metadata-item {
  display: inline-block;
}

.inline-newsletter-metadata {
  margin: 10px 0;
  display: inline-block;
}

.newsletter-list {
  list-style: none;
  padding: 4px 0 12px 0;
  margin: 0;
  width: 100%;
}

.newsletter-item-pending {
  padding: 6px 0;
  margin: 6px 0;
  /*Depressed box shadow*/
  box-shadow: rgba(0, 0, 0, 0.1) 2px 0px 5px;
  border-radius: 4px;
  background-color: #f5f5f5;
  transition: background-color 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
}


.newsletter-item-pending:hover, .newsletter-item-pending button {
/*  show the normal cursor, not a pointer*/
  cursor: default;
}

.newsletter-item {
  padding: 6px 0;
  margin: 6px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 0px 5px;
  border-radius: 4px;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
}

.newsletter-item:hover {
  background-color: #f5f5f5;
}

.newsletter-item-content-left {
  display: flex;
  flex-direction: column;
}

.newsletter-title {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 6px 12px;
}

.newsletter-item:hover .newsletter-title {
  color: #5D5DED;
}

.newsletter-status {
  padding: 2px 8px;
  margin-left: 12px;
  margin-bottom: 4px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 5px;
  font-size: 0.85rem;
  text-align: center;
  width: fit-content;
}

.blueprint-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 0px 16px;
  font-weight: bold;
  margin-top: 16px;
}

.blueprint-header-right {
  padding: 0px 29px;
  min-width: 126px;
  display: inline-block;
  text-align: center;
}

.info-bubble {
  background-color: #d9d9d9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 16px;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 510px;
}

.secondary-text {
  font-size: 12px;
  max-width: 400px;
  margin-top: 16px;
}

.url-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0;
  width: 100%;
  max-width: 450px;
}

.url-input {
  padding: 12px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
  flex: 1;
  min-width: 236px;
  max-width: 380px;
}

.url-input:focus {
  border-color: #A0C4FF;
}

.url-input.disabled {
  background-color: #d9d9d9;
}

.trash-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.trash-icon svg {
  width: 2em;
  height: 2em;
  margin-left: 4px;
}

.breadcrumb-container {
  display: flex;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 1.5em;
}

.breadcrumb-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 2em;
  align-items: center;
}

.breadcrumb-item {
  color: gray;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 0.75em;
}

.breadcrumb-item.current {
  text-decoration: underline;
  color: black;
  font-weight: bold;
}

.breadcrumb-item.completed {
  color: gray;
}

.breadcrumb-item:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -1.5em;
  width: 1em;
  border-top: 1px solid black;
  transform: translateY(-50%);
}

.side-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 510px;
  height: 100%;
  background-color: #f4f4f4;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.side-panel.open {
  transform: translateX(0);
}

.side-panel-close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.feedback-textarea-input {
  width: 95%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font: inherit;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s ease;
  resize: vertical;
  margin-bottom: 8px;
}

.feedback-textarea-input:focus {
  border-color: #A0C4FF;
}

.audience-of-one-example-text {
  text-align: left;
  padding: 8px;
}

.list-tag-gray {
  padding: 10px 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  color: #333;
  font-size: 0.85rem;
  display: inline-block;
  min-width: 126px;
  text-align: center;
}

.list-tag-green-active {
  padding: 10px 20px;
  background-color: #44ad54;
  border-radius: 10px;
  color: #ffffff;
  font-size: 0.85rem;
  display: inline-block;
  text-align: center;
}

.blueprint-section-prop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 16px;
  padding: 4px 16px;
}

.blueprint-section-dropdown {
  width: 186px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='%23333' d='M7 10l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.instant-sample-loading-message {
  animation: fade-in-out 4s ease-in-out infinite;
}
